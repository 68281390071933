import { html, TemplateResult } from "lit";

import Route from "@/model/vrr/route";
import { getIcon } from "@/controller/vrr/api";

import details from "./details";
import summary from "./summary";
import { times } from "./time";

export function renderRoutes(routes: Route[], dateTime: string, id: string): TemplateResult {
    return html`
      ${summary(routes, dateTime)}
      <div class="routes__list">
        ${routes.map((r, index) => route(`${id}-${index}`, r))}
      </div>
    `;
}

export function renderRoutesError(): TemplateResult {
    return html`
    <div>Es ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.</div>
  `;
}

function route(id: string, route: Route): TemplateResult {
    const types = route.partialRoutes
        .map((partialRoute, index) =>
            html`
          <span class="route__type ${partialRoute.meansOfTransport.footpath ? "icon-walk" : getIcon(partialRoute.meansOfTransport.id)}"></span>
          ${index < route.partialRoutes.length - 1 ? html`<span class="icon-arrow-right"></span>` : ""}
        `);

    return html`
      <div class="route-wrapper" data-route="${id}">
        <div class="route">
          ${times(route.origin, route.destination)}
          <div class="route__duration">
            <div>${route.duration}</div>
            <div>${types}</div>
          </div>
          <div class="route__changes-fare">
            <div class="route__changes">${route.changes ? route.changes : ""} ${route.changes > 1 ? "Umstiege" : route.changes === 0 ? "" : "Umstieg"}</div>
            <div class="route__fare">${route.fare ? `Preisstufe ${route.fare}` : "Preisauskunft nicht möglich"}</div>
          </div>
          <div class="route__expand" data-route-expand>
            <span class="icon-chevron-down"></span>
          </div>
        </div>
        ${details(route, id)}
      </div>
    `;
}
