import { Type } from "class-transformer";

class VrrDate {
    day: string;
    month: string;
    year: string;
    weekday: string;

    public toDate() {
        return new Date(Number.parseInt(this.year), Number.parseInt(this.month) - 1, Number.parseInt(this.day));
    }
}

export default class TrafficInformation {
    title: string;

    content: string;

    @Type(() => VrrDate)
        from: VrrDate;

    @Type(() => VrrDate)
        to: VrrDate;

    lines: {
      [id: string]: string
    };

    stops: {
      [id: string]: string
    };
}
