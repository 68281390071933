import { html, TemplateResult } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import TrafficInformationModel from "@/model/vrr/traffic-information";

export function renderTrafficInformation(trafficInformation: TrafficInformationModel[]): TemplateResult[] {
    return trafficInformation.map(value => {
        return html`
        <div class="traffic-information__item" data-traffic-information-item>
            <div class="traffic-information__title" data-traffic-information-item-title>
                <span class="traffic-information__date">${value.from.day}.${value.from.month}.${value.from.year} - ${value.to.day}.${value.to.month}.${value.to.year}</span>
                <span class="traffic-information__text">${value.title}</span>
            </div>
            <div class="traffic-information__content-wrapper" data-traffic-information-item-content-wrapper>
                <div class="traffic-information__content" data-traffic-information-item-content>${unsafeHTML(value.content)}</div>
            </div>
        </div>
    `;
    });
}

export function renderTrafficInformationNoResults(): TemplateResult {
    return html`
        <div>Zurzeit liegen keine Meldungen vor.</div>
    `;
}

export function renderTrafficInformationError(): TemplateResult {
    return html`
        <div>Es ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.</div>
    `;
}

export function renderTrafficInformationLineFilter(lines: Array<{ id: string; name: string }>): TemplateResult {
    return renderTrafficInformationSelectFilter(lines, "Alle Linien");
}

export function renderTrafficInformationStopFilter(stops: Array<{ id: string; name: string }>): TemplateResult {
    return renderTrafficInformationSelectFilter(stops, "Alle Haltestellen");
}

function renderTrafficInformationSelectFilter(options: Array<{ id: string; name: string }>, allLabel: string): TemplateResult {
    const option = (option: { id: string; name: string}) => html`
        <option value="${option.id}" >${option.name}</option>
    `;

    return html`
        <select class="traffic-information-select-filter__input" data-select-custom>
            <option value="all" selected>${allLabel}</option>
            ${options.map(option)}
        </select>
    `;
}
