import autocomplete from "autocompleter";

type AutocompleteItem = {
  label: string
  group?: string
  link?: string
};

type SearchResultDocument = {
  title: string
  link: string
  content: string
};

const TOP_RESULT = "top-result";

export class SearchAutocomplete {
    private static instance: SearchAutocomplete;

    public static getInstance(): SearchAutocomplete {
        if (!this.instance) {
            this.instance = new SearchAutocomplete();
        }
        return this.instance;
    }

    private constructor() {
        const forms: NodeListOf<HTMLFormElement> = document.querySelectorAll("form[data-suggest]");
        forms.forEach(form => {
            const url = form.dataset.suggest + "&tx_solr[queryString]=";
            const input = form.querySelector("input[data-suggest]") as HTMLInputElement;
            if (!input) {
                console.error("Search Form with data-suggest attribute does not contain input with same attribute");
            }

            const topResultsTitle = input.dataset.topResults || "";

            const preventFormSubmit = (event: Event) => event.preventDefault();

            autocomplete<AutocompleteItem>({
                input,
                fetch: (text, update) => {
                    const ajaxRequest = new XMLHttpRequest();

                    ajaxRequest.open("GET", url + text.toLocaleLowerCase(), true);

                    ajaxRequest.onreadystatechange = () => {
                        if (ajaxRequest.readyState === 4 && ajaxRequest.status === 200) {
                            const json = ajaxRequest.responseText;
                            const result = JSON.parse(json);
                            if (result.suggestions) {
                                const suggestions = Object.keys(result.suggestions).map(label => ({ label }));
                                const topResults = result.documents.map((document: SearchResultDocument) => ({ label: document.title, group: TOP_RESULT, link: document.link }));
                                const items = suggestions.concat(topResults);
                                update(items);
                            } else {
                                update([]);
                            }
                        }
                    };
                    if (text.length > 2) {
                        ajaxRequest.send();
                    } else {
                        update([]);
                    }
                },
                debounceWaitMs: 500,
                onSelect: (item) => {
                    form.removeEventListener("submit", preventFormSubmit);
                    if (item.group === TOP_RESULT) {
                        form.addEventListener("submit", preventFormSubmit);
                        window.location.assign(item.link ?? "");
                    } else {
                        input.value = item.label ?? "";
                    }
                },
                render: (item, searchTerm) => {
                    const div = document.createElement("div");
                    div.classList.add("search-autocomplete__item");

                    if (item.group === TOP_RESULT) {
                        div.classList.add("search-autocomplete__item--top-result");
                        const a = document.createElement("a");
                        a.href = item.link ?? "";
                        this.setText(a, searchTerm, item.label, "em");
                        div.appendChild(a);
                    } else {
                        this.setText(div, searchTerm, item.label, "strong");
                    }

                    return div;
                },
                renderGroup: (name: string) => {
                    if (name === TOP_RESULT) {
                        const div = document.createElement("div");
                        div.classList.add("search-autocomplete__group");
                        div.textContent = topResultsTitle;
                        return div;
                    }
                    return undefined;
                },
                customize: (input, _inputRect, container) => {
                    // move container after input
                    input.parentNode?.insertBefore(container, input.nextSibling);
                    container.style.top = "100%";
                    container.style.left = "0";
                },
                showOnFocus: true,
                disableAutoSelect: true,
                className: "search-autocomplete",
            });
        });
    }

    private setText(element: Element, searchTerm: string, label: string, highlightTag: "em" | "strong"): void {
        const labelParts = label.split(new RegExp(`(${searchTerm})`, "i"));

        labelParts.forEach((labelPart) => {
            if (labelPart.toLocaleLowerCase() === searchTerm.toLocaleLowerCase()) {
                const highlight = document.createElement(highlightTag);
                highlight.textContent = labelPart;
                element.appendChild(highlight);
            } else {
                const text = document.createTextNode(labelPart);
                element.appendChild(text);
            }
        });
    }
}
