import { html, TemplateResult } from "lit";
import Point from "@/model/vrr/point";

export function times(origin: Point, destination: Point): TemplateResult {
    return html`
      <div class="route-time__times">
        ${scheduledTime(origin)}
        ${scheduledTime(destination)}
      </div>
    `;
}

export function actualTime(point: Point): TemplateResult {
    return time(point.formattedTimeActual);
}

export function scheduledTime(point: Point): TemplateResult {
    return time(point.formattedTimeScheduled, html`<span class="route-time__delay ${point.delay > 0 ? " route-time__delay--red" : "route-time__delay--green"}">+${point.delay}</span>`);
}

function time(time: string, delay?: TemplateResult) {
    return html`
  <div class="route-time">
    <span class="route-time__value">${time}</span>
    ${delay}
  </div>
`;
}
