import { Api } from "./api";
import { renderTrafficInformation, renderTrafficInformationLineFilter, renderTrafficInformationStopFilter, renderTrafficInformationNoResults, renderTrafficInformationError } from "@/view/vrr/traffic-information";
import renderLoader from "@/view/vrr/loader";
import { render } from "lit";
import AccordionItemType from "@/model/accordion-item";
import TrafficInformationModel from "@/model/vrr/traffic-information";

const { Selects, DATA_ATTR_SELECT_CUSTOM } = await import("@/controller/selects");
const { default: AccordionItem } = await import("@/model/accordion-item");
const { getElementBySelector } = await import("@/util/elements");
const { CLASS_IS_VISIBLE, CLASS_IS_LOADING, CLASS_IS_EMPTY, CLASS_HAS_ERROR } = await import("@/util/layout");
const { DATA_TEXTFIELD, DATA_TEXTIELD_INPUT, DATA_TEXTIELD_CLEAR } = await import("@/controller/vrr/timetable");

export const DATA_TRAFFIC_INFORMATION = "data-traffic-information";
const DATA_TRAFFIC_INFORMATION_ITEM = DATA_TRAFFIC_INFORMATION + "-item";
const DATA_TRAFFIC_INFORMATION_ITEM_TITLE = DATA_TRAFFIC_INFORMATION_ITEM + "-title";
const DATA_TRAFFIC_INFORMATION_ITEM_CONTENT = DATA_TRAFFIC_INFORMATION_ITEM + "-content";
const DATA_TRAFFIC_INFORMATION_ITEM_CONTENT_WRAPPER = DATA_TRAFFIC_INFORMATION_ITEM_CONTENT + "-wrapper";
const DATA_TRAFFIC_INFORMATION_FILTERS = DATA_TRAFFIC_INFORMATION + "-filters";
const DATA_TRAFFIC_INFORMATION_FILTER = DATA_TRAFFIC_INFORMATION + "-filter";

export class TrafficInformationController {
    protected trafficInformation: TrafficInformationModel[] = [];
    protected container: HTMLElement;
    protected accordionItems: AccordionItemType[] = [];
    private filter: { line: string, date?: Date, stop: string } = { line: "all", date: undefined, stop: "all" };

    constructor(container: HTMLElement) {
        this.container = container;
    }

    protected get lines(): Array<{ id: string; name: string}> {
        const map = this.trafficInformation.map(trafficInformation => trafficInformation.lines).reduce((result, currentValue) => {
            Object.keys(currentValue).forEach(lineId => {
                if (!result[lineId]) {
                    result[lineId] = {
                        id: lineId,
                        name: currentValue[lineId],
                    };
                }
            });
            return result;
        }
        , {} as {[id: string]: { id: string; name: string }});
        return Object.values(map).sort(this.sortByName);
    }

    protected get stops(): Array<{ id: string; name: string}> {
        const map = this.trafficInformation.map(trafficInformation => trafficInformation.stops).reduce((result, currentValue) => {
            Object.keys(currentValue).forEach(stopId => {
                if (!result[stopId]) {
                    result[stopId] = {
                        id: stopId,
                        name: currentValue[stopId],
                    };
                }
            });
            return result;
        }
        , {} as {[id: string]: { id: string; name: string }});
        return Object.values(map).sort(this.sortByName);
    }

    private sortByName(a: { id: string; name: string; }, b: { id: string; name: string; }): number {
        return a.name > b.name ? 1 : -1;
    }

    private get filteredTrafficInformation(): TrafficInformationModel[] {
        const lineFilter = (trafficInformation: TrafficInformationModel) => this.filter.line !== "all" ? !!trafficInformation.lines[this.filter.line] : true;
        const stopFilter = (trafficInformation: TrafficInformationModel) => this.filter.stop !== "all" ? !!trafficInformation.stops[this.filter.stop] : true;
        const dateFilter = (trafficInformation: TrafficInformationModel) => this.filter.date ? this.filter.date >= trafficInformation.from.toDate() && this.filter.date <= trafficInformation.to.toDate() : true;

        return this.trafficInformation
            .filter(trafficInformation => !!trafficInformation.title)
            .filter(lineFilter)
            .filter(stopFilter)
            .filter(dateFilter);
    }

    protected loadTrafficInformation(unplanned?: boolean): Promise<TrafficInformationModel[]> {
        if (this.trafficInformation.length === 0) {
            render(renderLoader, this.container);
            this.container.classList.add(CLASS_IS_LOADING);
            this.container.classList.remove(CLASS_HAS_ERROR);
            return Api.getInstance().getTrafficInformation(unplanned)
                .then(trafficInformation => {
                    this.trafficInformation = trafficInformation;
                    return trafficInformation;
                }).catch((e: Error) => {
                    this.container.classList.add(CLASS_HAS_ERROR);
                    render(renderTrafficInformationError(), this.container);
                    return Promise.reject(e);
                }).finally(() => this.container.classList.remove(CLASS_IS_LOADING));
        } else {
            return Promise.resolve(this.trafficInformation);
        }
    }

    protected showTrafficInformation(trafficInformation: TrafficInformationModel[]): void {
        if (trafficInformation.length === 0) {
            this.container.classList.add(CLASS_IS_EMPTY);
            render(renderTrafficInformationNoResults(), this.container);
            return;
        }
        this.container.classList.remove(CLASS_IS_EMPTY);
        this.accordionItems.forEach(item => item.removeEventListener());
        this.accordionItems = [];

        render(renderTrafficInformation(trafficInformation), this.container);

        setAccordionItems(this.container, this.accordionItems);
    }

    protected setFilter(uid: string): void {
        const filtersContainer = getElementBySelector(`[${DATA_TRAFFIC_INFORMATION_FILTERS}="${uid}"]`) as HTMLElement;

        const lineFilterContainer = getElementBySelector(`[${DATA_TRAFFIC_INFORMATION_FILTER}="lines"]`, filtersContainer) as HTMLElement;
        lineFilterContainer.innerHTML = "";
        render(renderTrafficInformationLineFilter(this.lines), lineFilterContainer);

        const stopFilterContainer = getElementBySelector(`[${DATA_TRAFFIC_INFORMATION_FILTER}="stops"]`, filtersContainer) as HTMLElement;
        stopFilterContainer.innerText = "";
        render(renderTrafficInformationStopFilter(this.stops), stopFilterContainer);

        Selects.getInstance().init();

        this.addSelectListener("line", lineFilterContainer);
        this.addSelectListener("stop", stopFilterContainer);

        const dateFilterContainer = getElementBySelector(`[${DATA_TRAFFIC_INFORMATION_FILTER}="date"]`, filtersContainer);
        const dateFilterTextfield = getElementBySelector(`[${DATA_TEXTFIELD}="traffic-information-filter-date"]`, dateFilterContainer);
        const dateFilterTextfieldInput = getElementBySelector(`[${DATA_TEXTIELD_INPUT}]`, dateFilterTextfield) as HTMLInputElement;
        const dateFilterTextfieldClear = getElementBySelector(`[${DATA_TEXTIELD_CLEAR}]`, dateFilterTextfield) as HTMLElement;

        dateFilterTextfieldClear.addEventListener("click", () => {
            this.filter.date = undefined;
            dateFilterTextfieldInput.value = "";
            this.showTrafficInformation(this.filteredTrafficInformation);
            dateFilterTextfieldClear.classList.remove(CLASS_IS_VISIBLE);
        });

        dateFilterTextfieldInput.addEventListener("change", () => {
            this.filter.date = dateFilterTextfieldInput.valueAsDate || undefined;
            this.showTrafficInformation(this.filteredTrafficInformation);
            dateFilterTextfieldClear.classList.add(CLASS_IS_VISIBLE);
        });
    }

    private addSelectListener(type: "stop" | "line", filterContainer: Element): void {
        const filterSelectElement = getElementBySelector(`[${DATA_ATTR_SELECT_CUSTOM}]`, filterContainer);

        filterSelectElement.addEventListener("choice", (event) => {
            this.filter[type] = (event as CustomEvent).detail.choice.value;
            this.showTrafficInformation(this.filteredTrafficInformation);
        });
    }
}

export function setAccordionItems(container: HTMLElement, accordionItems: AccordionItemType[]): void {
    const trafficInformationItems = container.querySelectorAll(`[${DATA_TRAFFIC_INFORMATION_ITEM}]`);

    trafficInformationItems.forEach(diversionsItem => {
        const title = getElementBySelector(`[${DATA_TRAFFIC_INFORMATION_ITEM_TITLE}]`, diversionsItem);
        const contentWrapper = getElementBySelector(`[${DATA_TRAFFIC_INFORMATION_ITEM_CONTENT_WRAPPER}]`, diversionsItem) as HTMLElement;
        const content = getElementBySelector(`[${DATA_TRAFFIC_INFORMATION_ITEM_CONTENT}]`, contentWrapper);

        const accordionItem = new AccordionItem(contentWrapper, content, title);
        accordionItem.addEventListener();
        accordionItems.push(accordionItem);
    });
}
