export const DATA_PROP_CONTENT = "tooltipContent";
export const DATA_ATTR_CONTENT = "data-tooltip-content";
export const DATA_ATTR_TRIGGER = "data-tooltip-trigger";

const { TransientControlElement } = await import("./transient-control-element");

export class Tooltip extends TransientControlElement {
    constructor(element: HTMLElement) {
        super(element, DATA_PROP_CONTENT, DATA_ATTR_TRIGGER);
    }
}
