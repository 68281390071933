import { html, TemplateResult } from "lit";
import Route from "@/model/vrr/route";
import partialRoute from "./partial-route";

export default function details(route: Route, id: string): TemplateResult {
    return html`
        <div class="route-details-wrapper" data-route-details-wrapper>
          <div class="route-details" data-route-details>
            ${header}
            ${route.partialRoutes.map((pr, partialRouteIndex: number) => partialRoute(pr, `${id}-${partialRouteIndex}`))}
            <div class="route-details__shop">
              <a class="button" href="https://ticketservice.bogestra.de/authentication/login?organization=VRR&client=BOGESTRA" target="_blank">Zum Ticketshop</a>
            </div>
          </div>
        </div>
    `;
}

const header = html`
  <div class="route-details__heading">
    <div class="route-details__time-stop">
      <div class="route-details__time">Ab / An</div>
      <div class="route-details__stop">Haltestelle</div>
    </div>
    <div class="route-details__platform">Gleis</div>
    <div class="route-details__product">Linie / Richtung</div>
  </div>
`;
