import { TrafficInformationController, DATA_TRAFFIC_INFORMATION } from "./traffic-information";

const { getElementBySelector } = await import("@/util/elements");

const { Tabs } = await import("@/controller/tabs");

const TAB_GROUP_NAME = "route";
const TAB_NAME = "traffic-information-planned";
const UID = "planned";

export class TrafficInformationPlannedController extends TrafficInformationController {
    private static instance: TrafficInformationPlannedController;

    private get isResultPage(): boolean {
        return this.container.dataset.type === "page";
    }

    public static getInstance(): TrafficInformationPlannedController {
        if (!TrafficInformationPlannedController.instance) {
            TrafficInformationPlannedController.instance = new TrafficInformationPlannedController();
        }

        return TrafficInformationPlannedController.instance;
    }

    private constructor() {
        const container = getElementBySelector(`[${DATA_TRAFFIC_INFORMATION}="${UID}"]`) as HTMLElement;
        super(container);
        const tabs = Tabs.getInstance().tabsMap;
        const tabsEntry = tabs[TAB_GROUP_NAME];
        const tab = tabsEntry.tabs.find(t => t.uid === TAB_NAME);

        if (!tab) {
            throw new Error(`Tab with name ${TAB_NAME} not found`);
        }

        if (this.isResultPage) {
            this.loadTrafficInformation().then((trafficInformation) => {
                this.setFilter(UID);
                this.showTrafficInformation(trafficInformation);
            });
        } else {
            tab.addEventListener("show", () => {
                this.loadTrafficInformation().then((trafficInformation) => {
                    this.showTrafficInformation(trafficInformation.slice(0, 5));
                });
            });
        }
    }
}
