import { DATA_TRAFFIC_INFORMATION, setAccordionItems } from "./traffic-information";
import AccordionItem from "@/model/accordion-item";

const { Tabs } = await import("@/controller/tabs");
const { getElementBySelector } = await import("@/util/elements");
const { TAB_GROUP_NAME, TAB_NAME } = await import("@/controller/vrr/traffic-information-unplanned");

const UID = "unplanned-manual";

export class TrafficInformationUnplannedManualController {
    private static instance: TrafficInformationUnplannedManualController;
    private accordionItems: AccordionItem[] = [];

    public static getInstance(): TrafficInformationUnplannedManualController {
        if (!TrafficInformationUnplannedManualController.instance) {
            TrafficInformationUnplannedManualController.instance = new TrafficInformationUnplannedManualController();
        }

        return TrafficInformationUnplannedManualController.instance;
    }

    private constructor() {
        const container = getElementBySelector(`[${DATA_TRAFFIC_INFORMATION}="${UID}"]`) as HTMLElement;
        const tabs = Tabs.getInstance().tabsMap;
        const tabsEntry = tabs[TAB_GROUP_NAME];
        const tab = tabsEntry.tabs.find(t => t.uid === TAB_NAME);

        if (!tab) {
            throw new Error(`Tab with name ${TAB_NAME} not found`);
        }

        this.accordionItems.forEach(item => item.removeEventListener());
        this.accordionItems = [];

        setAccordionItems(container, this.accordionItems);
    }
}
