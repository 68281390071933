import { Consenter } from "@remindgmbh/cookieconsenter";
import { JobbaseWidgetModule } from "./modules/jobbase-widget-module";
import { MatomoModule } from "./modules/matomo-module";
import { UserlikeModule } from "./modules/userlike-module";

export class CookieConsent {
    private static instance: CookieConsent;

    public static getInstance(): CookieConsent {
        if (!CookieConsent.instance) {
            CookieConsent.instance = new CookieConsent();
        }

        return CookieConsent.instance;
    }

    private constructor() {
        const cookieLinks = document.querySelectorAll("a[data-link='cookies']") as NodeListOf<HTMLAnchorElement>;
        cookieLinks.forEach(link => (link.href = "javascript: Cookiebot.renew()"));

        const consenter: Consenter = new Consenter();

        consenter.addModule("statistics", new JobbaseWidgetModule());
        consenter.addModule("statistics", new MatomoModule());
        consenter.addModule("necessary", new UserlikeModule());

        consenter.enable();
    }
}
