import { html, TemplateResult } from "lit";
import Route from "@/model/vrr/route";

export default function summary(routes: Route[], dateTime: string): TemplateResult {
    const route = routes[0];

    if (!route) {
        return html`
        <div class="routes-summary routes-summary--no-results">
            <div class="routes-summary__heading">Kein Ergebnis</div>
            <div class="routes-summary__row">Leider konnte zu Ihrer Anfrage keine Verbindung gefunden werden.</div>
        </div>
    `;
    } else {
        return html`
      <div class="routes-summary">
          <div class="routes-summary__heading">Verbindungen</div>
          <div class="routes-summary__row">
              <div class="routes-summary__label">Start:</div>
              <div class="routes-summary__value">${route.origin?.name}</div>
          </div>
          <div class="routes-summary__row">
              <div class="routes-summary__label">Ziel:</div>
              <div class="routes-summary__value">${route.destination.name}</div>
          </div>
          <div class="routes-summary__row">
              <div class="routes-summary__label">Datum:</div>
              <div class="routes-summary__value">${dateTime}</div>
          </div>
      </div>
  `;
    }
}
