import { Module } from "@remindgmbh/cookieconsenter";

/**
 * Matomo module
 */
export class MatomoModule extends Module {
    /**
     * Include Matomo initialization script
     */
    public run(): void {
        const _paq = window._paq = window._paq || [];
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(["trackPageView"]);
        _paq.push(["enableLinkTracking"]);
        (function() {
            const u = `//${window.TYPO3.settings.TS.matomo}/`;
            _paq.push(["setTrackerUrl", u + "matomo.php"]);
            _paq.push(["setSiteId", "1"]);
            const d = document;
            const g = d.createElement("script");
            const s = d.getElementsByTagName("script")[0];
            g.async = true;
            g.src = u + "matomo.js";
            s.parentNode?.insertBefore(g, s);
        })();
    }
}
