import { Modal, DATA_ATTR_CONTENT, DATA_PROP_CONTENT } from "@/model/modal";
import { LayoutUtil as LayoutUtilType } from "@/util/layout";

const { LayoutUtil } = await import("@/util/layout");

export class Modals {
    private static instance: Modals;

    private layoutUtil: LayoutUtilType;
    private _modalMap: { [name: string]: Modal } = {};

    public get modalMap(): { [key: string]: Modal } {
        return this._modalMap;
    }

    public static getInstance(): Modals {
        if (!Modals.instance) {
            Modals.instance = new Modals();
        }

        return Modals.instance;
    }

    private constructor() {
        this.layoutUtil = LayoutUtil.getInstance();
        this.refresh();
    }

    public refresh(): void {
        const modalElements: NodeListOf<HTMLElement> = document.querySelectorAll(`[${DATA_ATTR_CONTENT}]`);
        modalElements.forEach(modalElement => {
            const modalName = modalElement.dataset[DATA_PROP_CONTENT];
            if (!modalName) {
                throw new Error("No name assigned to tabs");
            }

            if (!this.modalMap[modalName]) {
                const modal = new Modal(modalElement);
                this.modalMap[modalName] = modal;
                document.body.appendChild(modal.wrapper || modal.element);

                modal.triggers.forEach(trigger => {
                    trigger.addEventListener("click", () => {
                        modal.show(trigger);
                        this.layoutUtil.disabledScrollbar();
                    });
                });
                if (modal.closeButton) {
                    modal.closeButton.addEventListener("click", () => this.close(modal));
                }
                if (modal.wrapper) {
                    modal.wrapper.addEventListener("click", (e) => {
                        if (e.target === modal.wrapper) {
                            this.close(modal);
                        }
                    });
                }
            }
        });
    }

    private close(modal: Modal): void {
        modal.hide();
        this.layoutUtil.enableScrollbar();
    }
}
