const { ControlElement } = await import("./control-element");

const DATA_ATTR_TAB_LINK = "data-tab-link";
const DATA_PROP_TAB_CONTENT = "tabContent";
const DATA_ATTR_TAB_CLOSE = "data-tab-close";

export class Tab extends ControlElement {
    private _closeButton?: HTMLElement;
    private _showCallback: () => void | undefined;

    constructor(content: HTMLElement, tabs: HTMLElement) {
        super(content, DATA_PROP_TAB_CONTENT, DATA_ATTR_TAB_LINK, tabs);
        this._closeButton = this.firstTrigger.querySelector(`[${DATA_ATTR_TAB_CLOSE}]`) as HTMLElement;
    }

    public addEventListener(type: "show", callback: () => void): void {
        if (type === "show") {
            this._showCallback = callback;
        }
    }

    public get closeButton(): HTMLElement | undefined {
        return this._closeButton;
    }

    public show(): void {
        super.show();
        if (this._showCallback) {
            this._showCallback();
        }
    }
}
