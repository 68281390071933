export const CLASS_IS_ACTIVE = "is-active";
export const CLASS_IS_VISIBLE = "is-visible";
export const CLASS_IS_LOADING = "is-loading";
export const CLASS_IS_INVISIBLE = "is-invisible";
export const CLASS_IS_COLLAPSED = "is-collapsed";
export const CLASS_IS_EXPANDED = "is-expanded";
export const CLASS_IS_DISABLED = "is-disabled";
export const CLASS_IS_EMPTY = "is-empty";
export const CLASS_HAS_ERROR = "has-error";
const DATA_ATTR_CONTENT = "data-content";
const DATA_ATTR_HEADER = "data-header";
const DATA_ATTR_SNACKBAR_CLOSE = "data-snackbar-close";
const DATA_ATTR_SNACKBAR_TEXT = "data-snackbar-text";
const ID_SNACKBAR = "snackbar";

export class LayoutUtil {
    private static instance: LayoutUtil;

    private content: HTMLElement;
    private header: HTMLElement;
    private snackbar: HTMLElement;
    private snackbarClose: HTMLElement;
    private snackbarText: HTMLElement;
    private scrollPosition: number;

    public static getInstance(): LayoutUtil {
        if (!LayoutUtil.instance) {
            LayoutUtil.instance = new LayoutUtil();
        }

        return LayoutUtil.instance;
    }

    private constructor() {
        this.content = this.getContentElement();
        this.header = this.getHeaderElement();
        this.snackbar = this.getSnackbarElement();
        this.snackbarClose = this.getSnackbarCloseElement();
        this.snackbarText = this.getSnackbarTextElement();
    }

    public get scrollingDisabled(): boolean {
        return this.content.classList.contains(CLASS_IS_DISABLED);
    }

    public disabledScrollbar(): void {
        this.scrollPosition = pageYOffset;
        this.content.classList.add(CLASS_IS_DISABLED);
        this.content.style.top = `${-this.scrollPosition}px`;
    }

    public enableScrollbar(): void {
        this.content.classList.remove(CLASS_IS_DISABLED);
        this.content.style.top = "0";
        window.scrollTo({ top: this.scrollPosition });
    }

    public collapseHeader(): void {
        this.header.classList.add(CLASS_IS_COLLAPSED);
        this.content.classList.add(CLASS_IS_EXPANDED);
    }

    public expandHeader(): void {
        this.header.classList.remove(CLASS_IS_COLLAPSED);
        this.content.classList.remove(CLASS_IS_EXPANDED);
    }

    public enableSnackbar(text: string): void {
        this.snackbar.classList.add(CLASS_IS_VISIBLE);
        this.snackbarText.textContent = text;
        this.snackbarClose.addEventListener("click", this.disableSnackbar.bind(this));

        setTimeout(() => {
            this.disableSnackbar();
        }, 10000);
    }

    public disableSnackbar(): void {
        this.snackbarClose.removeEventListener("click", this.disableSnackbar);
        this.snackbar.classList.remove(CLASS_IS_VISIBLE);
    }

    private getContentElement(): HTMLElement {
        const content: HTMLElement | null = document.querySelector(`[${DATA_ATTR_CONTENT}]`);
        if (!content) {
            throw new Error("Content element not found");
        }
        return content;
    }

    private getHeaderElement(): HTMLElement {
        const header: HTMLElement | null = document.querySelector(`[${DATA_ATTR_HEADER}]`);
        if (!header) {
            throw new Error("Header not found");
        }
        return header;
    }

    private getSnackbarElement(): HTMLElement {
        const snackbar: HTMLElement | null = document.getElementById(ID_SNACKBAR);
        if (!snackbar) {
            throw new Error("Snackbar not found");
        }
        return snackbar;
    }

    private getSnackbarCloseElement(): HTMLElement {
        const snackbarClose: HTMLElement | null = this.snackbar.querySelector(`[${DATA_ATTR_SNACKBAR_CLOSE}]`);
        if (!snackbarClose) {
            throw new Error("Snackbar Close not found");
        }
        return snackbarClose;
    }

    private getSnackbarTextElement(): HTMLElement {
        const snackbarText: HTMLElement | null = this.snackbar.querySelector(`[${DATA_ATTR_SNACKBAR_TEXT}]`);
        if (!snackbarText) {
            throw new Error("Snackbar Text not found");
        }
        return snackbarText;
    }
}

// Make sure possible variables match :root in /Resources/Scss/definitions/_breakpoints.scss
export function getBreakpoint(breakpoint: "desktop-min" | "mobile-max" | "sub-nav-one-col-max"): string {
    return getComputedStyle(document.documentElement).getPropertyValue(`--${breakpoint}-width`);
}

export function handleMediaQuery(mediaQuery: string, listener: (mediaQueryListEvent: MediaQueryListEvent) => void): void {
    const mql = window.matchMedia(mediaQuery);
    mql.addEventListener ? mql.addEventListener("change", listener) : mql.addListener(listener);
}
