export default class Point {
    name: string;
    place: string;
    usage: "departure" | "arrival";
    platform: string;
    timeActual: string;
    timeScheduled: string;

    public get formattedTimeScheduled(): string {
        return new Date(this.timeScheduled).toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" });
    }

    public get formattedTimeActual(): string {
        return new Date(this.timeActual).toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" });
    }

    public get formattedPlatform(): string {
        return this.platform ? this.platform.startsWith("Gleis") ? this.platform : `Gleis ${this.platform}` : "";
    }

    public get delay(): number {
        const delay = new Date(this.timeActual).getTime() - new Date(this.timeScheduled).getTime();
        const delayInMinutes = Math.floor((delay / 1000) / 60);
        return delayInMinutes;
    }
}
