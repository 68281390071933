import { Type } from "class-transformer";
import { MeansOfTransport, getEnum, MeansOfTransportType } from "@/controller/vrr/api";
import Point from "./point";

export default class PartialRoute {
    minutes: number;
    footpathAfterDuration?: number;
    info: string[];
    warning: string[];

    @Type(() => Point)
        points: Point[];

    meansOfTransport: MeansOfTransport;

    public get origin(): Point {
        return this.getPoint("departure");
    }

    public get destination(): Point {
        return this.getPoint("arrival");
    }

    public get originPlatfrom(): string | undefined {
        return this.getPlatform(this.origin);
    }

    public get destinationPlatfrom(): string | undefined {
        return this.getPlatform(this.destination);
    }

    private getPlatform(point: Point): string | undefined {
        if (!point.platform) {
            return;
        }
        const enumValue = getEnum(this.meansOfTransport.id);
        const isBus = enumValue === MeansOfTransportType.Bus;
        return point.platform ? point.platform.includes("Gleis") ? point.platform : `${isBus ? "Bussteig" : "Gleis"} ${point.platform}` : "";
    }

    private getPoint(type: "arrival" | "departure"): Point {
        const point = this.points.find(point => point.usage === type);
        if (!point) {
            throw new Error(`No point with usage '${type}' found in partial route`);
        }
        return point;
    }
}
