const { CLASS_IS_VISIBLE, CLASS_IS_ACTIVE } = await import("@/util/layout");

const { ControlElement } = await import("./control-element");

export const DATA_ATTR_CONTENT = "data-modal-content";
export const DATA_ATTR_CONTENT_WRAPPER = "data-modal-content-wrapper";
export const DATA_PROP_CONTENT = "modalContent";
const DATA_ATTR_TRIGGER = "data-modal-trigger";
const DATA_ATTR_CLOSE = "data-modal-close";

export class Modal extends ControlElement {
    private _closeButton?: HTMLElement;
    private _wrapper?: HTMLElement;
    private _showCallback?: (trigger: HTMLElement) => void;

    constructor(content: HTMLElement) {
        super(content, DATA_PROP_CONTENT, DATA_ATTR_TRIGGER);
        this._wrapper = content.closest(`[${DATA_ATTR_CONTENT_WRAPPER}=${this.uid}]`) as HTMLElement;
        this._closeButton = document.querySelector(`[${DATA_ATTR_CLOSE}=${this.uid}]`) as HTMLElement;
    }

    public show(trigger: HTMLElement): void {
        if (!this.wrapper) {
            super.show(trigger);
        } else {
            trigger.classList.add(CLASS_IS_ACTIVE);
            this.wrapper.classList.add(CLASS_IS_VISIBLE);
        }
        if (this._showCallback) {
            this._showCallback(trigger);
        }
    }

    public hide(): void {
        if (!this.wrapper) {
            super.hide();
        } else {
            this.activeTrigger?.classList.remove(CLASS_IS_ACTIVE);
            this.wrapper.classList.remove(CLASS_IS_VISIBLE);
        }
    }

    public get closeButton(): HTMLElement | undefined {
        return this._closeButton;
    }

    public get wrapper(): HTMLElement | undefined {
        return this._wrapper;
    }

    public addEventListener(type: "show", callback: (trigger: HTMLElement) => void): void {
        if (type === "show") {
            this._showCallback = callback;
        }
    }
}
