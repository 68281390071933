
export const DATA_PROP_CONTENT = "sidebarContent";
export const DATA_ATTR_CONTENT = "data-sidebar-content";
const DATA_ATTR_TRIGGER = "data-sidebar-trigger";
const DATA_PROP_LEVEL = "sidebarLevel";

const { ControlElement } = await import("./control-element");

export class Sidebar extends ControlElement {
    protected _level: number;
    protected _parent?: Sidebar;

    constructor(trigger: HTMLElement) {
        super(trigger, DATA_PROP_CONTENT, DATA_ATTR_TRIGGER);
        this.setLevel();
    }

    get level(): number {
        return this._level;
    }

    get parent(): Sidebar | undefined {
        return this._parent;
    }

    set parent(parent: Sidebar | undefined) {
        this._parent = parent;
    }

    protected setLevel(): void {
        const level = this.element.dataset[DATA_PROP_LEVEL];
        if (!level) {
            throw new Error("No level defined");
        }
        this._level = Number.parseInt(level);
    }
}
