import { html, TemplateResult } from "lit";

import PartialRoute from "@/model/vrr/partial-route";
import Point from "@/model/vrr/point";
import info from "./info";
import { scheduledTime, actualTime } from "./time";
import { getIcon } from "@/controller/vrr/api";

export default function partialRoute(partialRoute: PartialRoute, id: string): TemplateResult {
    return html`
        ${partialRoute.meansOfTransport.footpath ? walk(partialRoute) : transport(partialRoute, id)}
        ${change(partialRoute.footpathAfterDuration)}
      `;
}

function transport(partialRoute: PartialRoute, id: string) {
    return html`
    <div class="partial-route">
        <div class="partial-route__stops">
            ${stop(partialRoute.origin, scheduledTime)}
            ${stop(partialRoute.destination, scheduledTime)}
        </div>
        <div class="partial-route__platform${!partialRoute.originPlatfrom && !partialRoute.destinationPlatfrom ? " partial-route__platform--empty" : ""}">
            <div>${partialRoute.originPlatfrom ? `ab ${partialRoute.originPlatfrom}` : ""}</div>
            <div>${partialRoute.destinationPlatfrom ? `an ${partialRoute.destinationPlatfrom}` : ""}</div>
        </div>
        <div class="partial-route__product">
            <div>
                <span class="partial-route__type-icon ${getIcon(partialRoute.meansOfTransport.id)}"></span>
                <span class="partial-route__type-text">${partialRoute.meansOfTransport.name}</span>
                ${infoButton(partialRoute, id)}
            </div>
            <div>Richtung: ${partialRoute.meansOfTransport.destination}</div>
            ${info(partialRoute, id)}
        </div>
    </div>
    `;
}

function infoButton(partialRoute: PartialRoute, id: string) {
    if ((partialRoute.info.length + partialRoute.warning.length) > 0) {
        return html`
      <span class="partial-route__info icon-info" data-modal-trigger="partial-route-${id}"></span>
    `;
    } else {
        return "";
    }
}

function walk(partialRoute: PartialRoute) {
    const walkStop = html`
    <div class="partial-route">
        ${stop(partialRoute.meansOfTransport.footpath === "from" ? partialRoute.origin : partialRoute.destination, actualTime)}
    </div>
    `;

    const walk = html`
    <div class="partial-route__footpath">
        <span class="icon-walk"></span>
        <span>Fußweg ${partialRoute.minutes} Minuten</span>
    </div>
    `;

    return partialRoute.meansOfTransport.footpath === "from" ? html`${walkStop}${walk}` : html`${walk}${walkStop}`;
}

function stop(point: Point, time: (point: Point) => TemplateResult) {
    return html`
    <div class="partial-route__stop">
        ${time(point)}
        <div>${point.name}</div>
    </div>
    `;
}

function change(duration?: number) {
    return duration
        ? html`
          <div class="partial-route__change">
            <span class="icon-route-variant"></span>
            <span>Umstiegsdauer ${duration} Minuten</span>
          </div>
        `
        : "";
}
