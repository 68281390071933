import { Tooltip, DATA_ATTR_CONTENT, DATA_PROP_CONTENT } from "@/model/tooltip";

export class Tooltips {
    private static instance: Tooltips;

    private _tooltipMap: { [key: string]: Tooltip } = {};

    public get tooltipMap(): { [key: string]: Tooltip } {
        return this._tooltipMap;
    }

    public static getInstance(): Tooltips {
        if (!Tooltips.instance) {
            Tooltips.instance = new Tooltips();
        }

        return Tooltips.instance;
    }

    private constructor() {
        this.refresh();
    }

    public refresh(): void {
        const tooltipContentElements: NodeListOf<HTMLElement> = document.querySelectorAll(`[${DATA_ATTR_CONTENT}]`);

        tooltipContentElements.forEach(tooltipContentElement => {
            const tooltipUid = tooltipContentElement.dataset[DATA_PROP_CONTENT];
            if (!tooltipUid) {
                throw new Error("No uid assigned to tooltip");
            }

            if (!this._tooltipMap[tooltipUid]) {
                this._tooltipMap[tooltipUid] = new Tooltip(tooltipContentElement);
            }
        });
    }
}
