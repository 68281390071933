import { Module } from "@remindgmbh/cookieconsenter";

export class JobbaseWidgetModule extends Module {
    public run(): void {
    /** Select all replacement elements that display the consent message */
        const elements: NodeListOf<HTMLElement> = document.querySelectorAll("[data-cookie-consenter=\"jobbase-widget\"]");

        elements.forEach(element => {
            const scriptDiv = element.querySelector("[data-src]") as HTMLElement;

            const src = scriptDiv.dataset.src as string;

            /** Create the script element */
            const script = document.createElement("script");
            script.src = src;

            element.removeChild(scriptDiv);
            element.appendChild(script);
        });
    }
}
