import { Type } from "class-transformer";
import PartialRoute from "./partial-route";
import Point from "./point";

export default class Route {
    duration: string;

    changes: number;

    @Type(() => PartialRoute)
        partialRoutes: PartialRoute[];

    fare?: string;

    public get origin(): Point {
        return this.partialRoutes[0].origin;
    }

    public get destination(): Point {
        return this.partialRoutes[this.partialRoutes.length - 1].destination;
    }
}
