import StopInputType from "@/model/vrr/stop-input";
import { Stop } from "./api";

const { default: StopInput } = await import("@/model/vrr/stop-input");
const { getElementBySelector } = await import("@/util/elements");

const DATA_TIMETABLE = "data-timetable";
const DATA_TIMETABLE_STOPS_SWAP = DATA_TIMETABLE + "-stops-swap";

export default class StopInputs {
    private originInput: StopInputType;
    private destinationInput: StopInputType;

    constructor(container: Element) {
        this.originInput = new StopInput(container, "stop-from");
        this.destinationInput = new StopInput(container, "stop-to");

        const swap = getElementBySelector(`[${DATA_TIMETABLE_STOPS_SWAP}]`, container) as HTMLElement;
        swap.addEventListener("click", this.swap.bind(this));
    }

    private swap() {
        const stops = JSON.parse(JSON.stringify(this.originInput.stops));
        const inputValue = this.originInput.inputValue;
        const selectedStop = JSON.parse(JSON.stringify(this.originInput.selectedStop));

        this.originInput.stops = this.destinationInput.stops;
        this.originInput.inputValue = this.destinationInput.inputValue;
        this.originInput.selectedStop = this.destinationInput.selectedStop;

        this.destinationInput.stops = stops;
        this.destinationInput.inputValue = inputValue;
        this.destinationInput.selectedStop = selectedStop;
    }

    public validate(): boolean {
        const validOrigin = this.originInput.validate(true);
        const validDestination = this.destinationInput.validate(true);
        return validOrigin && validDestination;
    }

    public get origin(): Stop | null {
        return this.originInput.selectedStop;
    }

    public set origin(value: Stop | null) {
        this.originInput.selectedStop = value;
    }

    public get destination(): Stop | null {
        return this.destinationInput.selectedStop;
    }

    public set destination(value: Stop | null) {
        this.destinationInput.selectedStop = value;
    }
}
