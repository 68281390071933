import { Stop, Api } from "@/controller/vrr/api";
const { CLASS_IS_VISIBLE, CLASS_HAS_ERROR, LayoutUtil } = await import("@/util/layout");
const { outsideClickListener, getElementBySelector, setElementClass } = await import("@/util/elements");

const DATA_TEXTFIELD = "data-textfield";
const DATA_TEXTIELD_INPUT = DATA_TEXTFIELD + "-input";
const DATA_TEXTFIELD_AUTOCOMPLETE = DATA_TEXTFIELD + "-autocomplete";

const MAX_RESULTS = 5;
const MIN_CHARS = 3;
const DELAY = 300;

const ERROR_MESSAGE = "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.";

export default class StopInput {
    private activeOutsideClickListener: ((event: MouseEvent) => unknown) | null;
    private inputElement: HTMLInputElement;
    private autoCompleteElement: HTMLElement;
    private _stops: Stop[] = [];
    private _selectedStop: Stop | null = null;
    private validationInitialized = false;
    private required = false;

    get stops(): Stop[] {
        return this._stops;
    }

    set stops(value: Stop[]) {
        this._stops = value;
        this.autoCompleteElement.innerHTML = "";
        this._stops.slice(0, MAX_RESULTS).forEach(this.createStopElement.bind(this));
        this.addStopsEventListener();
    }

    get inputValue(): string {
        return this.inputElement.value;
    }

    set inputValue(value: string) {
        this.inputElement.value = value;
    }

    get selectedStop(): Stop | null {
        return this._selectedStop;
    }

    set selectedStop(stop: Stop | null) {
        if (stop) {
            this.inputValue = stop.name;
        }
        this._selectedStop = stop;
        this.hideStops();
    }

    get isValid(): boolean {
        return !this.inputElement.classList.contains(CLASS_HAS_ERROR);
    }

    constructor(container: Element, dataAttrValue: "stop-to" | "stop-from" | "stop-via") {
        this.required = dataAttrValue !== "stop-via";
        const textFieldElement = getElementBySelector(`[${DATA_TEXTFIELD}="${dataAttrValue}"]`, container) as HTMLInputElement;
        this.inputElement = getElementBySelector(`[${DATA_TEXTIELD_INPUT}]`, textFieldElement) as HTMLInputElement;
        this.autoCompleteElement = getElementBySelector(`[${DATA_TEXTFIELD_AUTOCOMPLETE}]`, textFieldElement) as HTMLElement;
        this.addInputEventListener();
    }

    public validate(force = false): boolean {
        if (force) {
            this.validationInitialized = true;
        }
        if (!this.validationInitialized && !this.inputValue) {
            return true;
        }
        const isValid = (!this.required && !this.inputValue) || !!this.selectedStop;

        setElementClass(this.inputElement, CLASS_HAS_ERROR, !isValid);
        this.validationInitialized = true;

        return isValid;
    }

    private createStopElement(stop: Stop): void {
        const stopDiv = document.createElement("div");
        stopDiv.innerText = stop.name;
        stopDiv.dataset.stopId = stop.id.toString();
        stopDiv.setAttribute("tabindex", "-1");
        this.autoCompleteElement.appendChild(stopDiv);
    }

    private addInputEventListener(): void {
        let timeout: number;

        this.inputElement.addEventListener("keydown", (event) => {
            if (event.key === "ArrowDown") {
                event.preventDefault();
                if (this.autoCompleteElement.children.length > 0) {
                    const firstChild = this.autoCompleteElement.children[0] as HTMLElement;
                    firstChild.focus();
                }
            }
        });

        this.inputElement.addEventListener("focus", () => {
            if (this.stops.length > 0) {
                this.showStops();
            }
        });

        this.inputElement.addEventListener("blur", (e) => {
            // Hide stops on blur, but only if new focus is NOT autocomplete element
            if (!(e.relatedTarget instanceof Element) || !this.autoCompleteElement.contains(e.relatedTarget)) {
                this.hideStops();
                this.validate();
            }
        });

        this.inputElement.addEventListener("input", () => {
            clearTimeout(timeout);

            const inputValue = this.inputElement.value;

            this._selectedStop = null;

            if (inputValue.length < MIN_CHARS) {
                this.autoCompleteElement.innerHTML = "";
                this.hideStops();
                this.stops = [];
                if (this.validationInitialized) {
                    this.validate();
                }
                return;
            }

            timeout = window.setTimeout(() => {
                Api.getInstance().getStops(inputValue).then(stops => {
                    if (inputValue !== this.inputElement.value) {
                        return;
                    }
                    this.stops = stops || [];

                    if (this.stops.length > 0) {
                        this._selectedStop = this.stops[0];
                    }

                    if (this.stops.length > 0 && this.inputElement === document.activeElement) {
                        this.showStops();
                    } else {
                        this.hideStops();
                    }

                    if (this.validationInitialized) {
                        this.validate();
                    }
                }).catch(() => {
                    LayoutUtil.getInstance().enableSnackbar(ERROR_MESSAGE);
                });
            }, DELAY);
        });
    }

    private addStopsEventListener() {
        for (let index = 0; index < this.autoCompleteElement.children.length; index++) {
            const stop = this.stops[index];
            const child = this.autoCompleteElement.children.item(index) as HTMLElement;

            child.addEventListener("click", () => { this.selectedStop = stop; });

            child.addEventListener("keydown", event => {
                switch (event.key) {
                case "Enter":
                case " ":
                    event.preventDefault();
                    this.selectedStop = stop;
                    break;
                case "ArrowUp":
                    event.preventDefault();
                    if (index > 0) {
                        const childAbove = this.autoCompleteElement.children.item(index - 1) as HTMLElement;
                        childAbove.focus();
                    } else {
                        this.inputElement.focus();
                    }
                    break;
                case "ArrowDown":
                    event.preventDefault();
                    if (index < this.autoCompleteElement.children.length - 1) {
                        const childBelow = this.autoCompleteElement.children.item(index + 1) as HTMLElement;
                        childBelow.focus();
                    }
                    break;
                default:
                    break;
                }
            });
        }
    }

    private showStops() {
        this.autoCompleteElement.classList.add(CLASS_IS_VISIBLE);
        this.addOutsideClickListener();
    }

    private hideStops() {
        this.autoCompleteElement.classList.remove(CLASS_IS_VISIBLE);
        this.removeOutsideClickListener();
    }

    private addOutsideClickListener() {
        this.activeOutsideClickListener = (event: MouseEvent) => {
            outsideClickListener(event, () => {
                this.hideStops();
            }, this.autoCompleteElement, this.inputElement);
        };
        document.addEventListener("click", this.activeOutsideClickListener);
    }

    private removeOutsideClickListener() {
        if (this.activeOutsideClickListener) {
            document.removeEventListener("click", this.activeOutsideClickListener);
        }
    }
}
