import { Tab } from "@/model/tab";
import { CLASS_IS_ACTIVE } from "@/util/layout";

const DATA_ATTR_TABS = "data-tabs";
const DATA_PROP_TABS = "tabs";
const DATA_ATTR_TAB_CONTENT = "data-tab-content";

export interface TabsEntry {
  tabs: Tab[]
  activeTab?: Tab
}

export class Tabs {
    private static instance: Tabs;

    private _tabsMap: { [name: string]: TabsEntry } = {};

    public static getInstance(): Tabs {
        if (!Tabs.instance) {
            Tabs.instance = new Tabs();
        }

        return Tabs.instance;
    }

    public get tabsMap(): { [name: string]: TabsEntry } {
        return this._tabsMap;
    }

    private constructor() {
        this.setTabs();
    }

    private setTabs(): void {
        const tabsElements: NodeListOf<HTMLElement> = document.querySelectorAll(`[${DATA_ATTR_TABS}]`);
        tabsElements.forEach(tabsElement => {
            const tabsName = tabsElement.dataset[DATA_PROP_TABS];
            if (!tabsName) {
                throw new Error("No name assigned to tabs");
            }

            const tabsEntry: TabsEntry = { tabs: [] };
            this._tabsMap[tabsName] = tabsEntry;

            const tabContentElements: NodeListOf<HTMLElement> = tabsElement.querySelectorAll(`[${DATA_ATTR_TAB_CONTENT}]`);
            tabContentElements.forEach((tabContentElement) => {
                const tab = new Tab(tabContentElement, tabsElement);
                tabsEntry.tabs.push(tab);

                this.bindEvents(tab, tabsEntry, tabsElement);
            });
        });
    }

    private bindEvents(tab: Tab, tabsEntry: TabsEntry, tabsElement: Element): void {
        tab.firstTrigger.addEventListener("click", () => {
            if (tabsEntry.activeTab) {
                this.removeActiveTab(tabsEntry, tabsElement);
            }
            this.setActiveTab(tabsEntry, tab, tabsElement);
        });
        if (tab.closeButton) {
            tab.closeButton.addEventListener("click", (e) => {
                e.stopImmediatePropagation();
                this.removeActiveTab(tabsEntry, tabsElement);
                tabsEntry.activeTab = undefined;
            });
        }
    }

    private removeActiveTab(tabsEntry: TabsEntry, tabsElement: Element) {
        tabsEntry.activeTab?.hide();
        tabsElement.classList.remove(CLASS_IS_ACTIVE);
    }

    private setActiveTab(tabsEntry: TabsEntry, tab: Tab, tabsElement: Element) {
        tab.show();
        tabsEntry.activeTab = tab;
        tabsElement.classList.add(CLASS_IS_ACTIVE);
    }
}
