import { Dropdown } from "@/model/dropdown";
import { DATA_ATTR_CONTENT, DATA_PROP_CONTENT, Sidebar } from "@/model/sidebar";
import { LayoutUtil as LayoutUtilType } from "@/util/layout";

const { Dropdowns } = await import("@/controller/dropdowns");
const { handleMediaQuery, getBreakpoint, CLASS_IS_ACTIVE, LayoutUtil } = await import("@/util/layout");

const DATA_ATTR_SIDEBAR_BACK_BUTTON = "data-sidebar-back-button";
const DATA_PROP_SIDEBAR_BACK_BUTTON = "sidebarBackButton";
const DROPDOWN_SEARCH = "search";

export class MobileNavigation {
    private static instance: MobileNavigation;

    private layoutUtil: LayoutUtilType;
    private openSidebarIds: { [level: number]: string } = {};
    private sidebars: { [sidebarId: string]: Sidebar } = {};
    private dropdowns: { [key: string]: Dropdown };

    public static getInstance(): MobileNavigation {
        if (!MobileNavigation.instance) {
            MobileNavigation.instance = new MobileNavigation();
        }

        return MobileNavigation.instance;
    }

    private constructor() {
        this.dropdowns = Dropdowns.getInstance().dropdownMap;
        this.layoutUtil = LayoutUtil.getInstance();

        this.setSidebars();
        this.bindTriggerEvents();
        this.bindBackButtonEvents();
        this.closeSidebarOnViewChange();
        this.closeSidebarOnOpenSearch();
    }

    private bindTriggerEvents(): void {
        Object.values(this.sidebars).forEach(sidebar => {
            sidebar.firstTrigger.addEventListener("click", () => this.toggleSidebar(sidebar));
        });
    }

    // Hide all sidebars when changing to desktop view
    private closeSidebarOnViewChange(): void {
        const breakpoint = getBreakpoint("desktop-min");
        handleMediaQuery(`(min-width: ${breakpoint})`, (mediaQuery) => {
            if (mediaQuery.matches) {
                this.hideSidebars();
            }
        });
    }

    private closeSidebarOnOpenSearch(): void {
        const searchDropdown = this.dropdowns[DROPDOWN_SEARCH];
        searchDropdown.firstTrigger.addEventListener("click", () => {
            if (Object.keys(this.openSidebarIds).length > 0) {
                this.hideSidebars();
            }
        });
    }

    private toggleSidebar(sidebar: Sidebar): void {
        // check if sidebar is opened
        const isOpen = this.openSidebarIds[sidebar.level] === sidebar.uid;

        this.hideSidebars(sidebar.level);

        // show sidebar if it wasn't opened
        if (!isOpen) {
            this.showSidebar(sidebar);
        }
    }

    private showSidebar(sidebar: Sidebar): void {
        this.openSidebarIds[sidebar.level] = sidebar.uid;
        sidebar.show();

        // disable scrollbar on content when first sidebar opens
        if (sidebar.level === 1) {
            this.layoutUtil.disabledScrollbar();
        }
    }

    private hideSidebar(sidebar: Sidebar): void {
        delete this.openSidebarIds[sidebar.level];
        sidebar.hide();

        // enable scrollbar on content when last sidebar closes
        if (sidebar.level === 1) {
            this.layoutUtil.enableScrollbar();
        }
    }

    // Hide all sidebars above (and including) threshold level
    private hideSidebars(thresholdLevel = 0): void {
        Object.keys(this.openSidebarIds).filter(level => Number.parseInt(level) >= thresholdLevel).forEach(level => {
            const parsedLevel = Number.parseInt(level);
            const sidebar = this.sidebars[this.openSidebarIds[parsedLevel]];
            this.hideSidebar(sidebar);
        });
    }

    private setSidebars(): void {
        const elements: NodeListOf<HTMLElement> = document.querySelectorAll(`[${DATA_ATTR_CONTENT}]`);

        elements.forEach(trigger => {
            const sidebar = new Sidebar(trigger);
            this.sidebars[sidebar.uid] = sidebar;
        });

        Object.values(this.sidebars).forEach(sidebar => {
            const parentSidebarElement: HTMLElement | null = sidebar.firstTrigger.closest(`[${DATA_ATTR_CONTENT}`);
            if (parentSidebarElement) {
                const parentSidebarUid = parentSidebarElement.dataset[DATA_PROP_CONTENT];
                if (!parentSidebarUid) {
                    throw new Error("Parent Sidebar UID is null -> Shouldn't happen because Parent Sidebar Element was found using UID data attribute");
                }
                sidebar.parent = this.sidebars[parentSidebarUid];
            }
        });
    }

    private bindBackButtonEvents(): void {
        const backButtonElements: NodeListOf<HTMLElement> = document.querySelectorAll(`[${DATA_ATTR_SIDEBAR_BACK_BUTTON}]`);
        backButtonElements.forEach(backButtonElement => {
            const targetLevelAttr = backButtonElement.dataset[DATA_PROP_SIDEBAR_BACK_BUTTON];

            if (!targetLevelAttr) {
                throw new Error("No target level for back button defined");
            }
            const targetLevel = Number.parseInt(targetLevelAttr);

            backButtonElement.addEventListener("click", () => {
                backButtonElement.classList.add(CLASS_IS_ACTIVE);
                this.hideSidebars(targetLevel + 1);
                backButtonElement.addEventListener("animationend", () => backButtonElement.classList.remove(CLASS_IS_ACTIVE));
            });
        });
    }
}
