import { render } from "lit";
import { Api, RequestInfo } from "./api";
import { renderRoutes, renderRoutesError } from "@/view/vrr/routes";
import loader from "@/view/vrr/loader";
import StopInputs from "./stop-inputs";
import DateInput from "@/model/vrr/date-input";
import AdvancedSearch from "@/model/vrr/advanced-search";
import { Modals } from "@/controller/modals";
import Route from "@/model/vrr/route";
import AccordionItemType from "@/model/accordion-item";

const { getElementBySelector, getRadioValue, setRadioValue, setElementClass } = await import("@/util/elements");
const { CLASS_IS_VISIBLE, CLASS_IS_LOADING, LayoutUtil } = await import("@/util/layout");
const { default: AccordionItem } = await import("@/model/accordion-item");

const DATA_ROUTES = "data-routes";
const DATA_ROUTE = "data-route";
const DATA_ROUTE_EXPAND = DATA_ROUTE + "-expand";
const DATA_ROUTE_DETAILS = DATA_ROUTE + "-details";
const DATA_ROUTE_DETAILS_WRAPPER = DATA_ROUTE_DETAILS + "-wrapper";

const DATA_TIMETABLE = "data-timetable";

export const DATA_TEXTFIELD = "data-textfield";
export const DATA_TEXTIELD_INPUT = DATA_TEXTFIELD + "-input";
export const DATA_TEXTIELD_CLEAR = DATA_TEXTFIELD + "-clear";

const DATA_MORE_ROUTES = "data-more-routes";
const DATA_MORE_ROUTES_BEFORE = DATA_MORE_ROUTES + "-before";
const DATA_MORE_ROUTES_AFTER = DATA_MORE_ROUTES + "-after";

const QUERY_PARAM_ORIGIN_ID = "originId";
const QUERY_PARAM_ORIGIN_NAME = "originName";
const QUERY_PARAM_DESTINATION_ID = "destinationId";
const QUERY_PARAM_DESTINATION_NAME = "destinationName";
const QUERY_PARAM_DATE = "date";
const QUERY_PARAM_TIME = "time";
const QUERY_PARAM_DEP_ARR = "depArr";

const RADIO_DEP_ARR = "dateTimeDepArr";

const ERROR_MESSAGE = "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.";

export class Timetable {
    private static instance: Timetable;

    private resultContainer: HTMLElement;
    private dateInput: DateInput;
    private timeInput: HTMLInputElement;
    private stopInputs: StopInputs;
    private container: HTMLElement;
    private advancedSearch: AdvancedSearch;
    private moreRoutes: Element;
    private accordionItems: AccordionItemType[] = [];

    private get queryParams(): URLSearchParams {
        const result = new URLSearchParams();

        function setParam(key: string, value?: string) {
            if (value) {
                result.set(key, value);
            }
        }

        setParam(QUERY_PARAM_ORIGIN_ID, this.stopInputs.origin?.id.toString());
        setParam(QUERY_PARAM_ORIGIN_NAME, this.stopInputs.origin?.name);
        setParam(QUERY_PARAM_DESTINATION_ID, this.stopInputs.destination?.id?.toString());
        setParam(QUERY_PARAM_DESTINATION_NAME, this.stopInputs.destination?.name);
        setParam(QUERY_PARAM_DATE, this.dateInput.value);
        setParam(QUERY_PARAM_TIME, this.timeInput.value);
        setParam(QUERY_PARAM_DEP_ARR, this.dateTimeDepArr);

        return result;
    }

    private get dateTimeDepArr(): string {
        return getRadioValue(RADIO_DEP_ARR, this.container);
    }

    private get isResultPage(): boolean {
        return this.container.dataset.timetable === "page";
    }

    public static getInstance(): Timetable {
        if (!Timetable.instance) {
            Timetable.instance = new Timetable();
        }
        return Timetable.instance;
    }

    private constructor() {
        this.container = document.querySelector(`[${DATA_TIMETABLE}]`) as HTMLElement;

        this.stopInputs = new StopInputs(this.container);
        this.setInputs();

        if (this.isResultPage) {
            this.advancedSearch = new AdvancedSearch(this.container);
            this.resultContainer = getElementBySelector(`[${DATA_ROUTES}]`) as HTMLElement;
            this.moreRoutes = getElementBySelector(`[${DATA_MORE_ROUTES}]`);
            const searchBeforeButton = getElementBySelector(`[${DATA_MORE_ROUTES_BEFORE}]`, this.moreRoutes);
            const searchAfterButton = getElementBySelector(`[${DATA_MORE_ROUTES_AFTER}]`, this.moreRoutes);

            searchBeforeButton.addEventListener("click", () => this.searchMore(searchBeforeButton, "before"));
            searchAfterButton.addEventListener("click", () => this.searchMore(searchAfterButton, "after"));

            this.searchByQueryParams();
        }

        this.container.addEventListener("submit", (e) => {
            e.preventDefault();
            this.isResultPage ? this.search() : this.redirectToSearchPage();
        });
    }

    private setInputs() {
        const now = new Date();
        const date = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, "0")}-${now.getDate().toString().padStart(2, "0")}`;
        const time = `${now.getHours().toString().padStart(2, "0")}:${now.getMinutes().toString().padStart(2, "0")}`;
        const dateTextfieldElement = getElementBySelector(`[${DATA_TEXTFIELD}="date"]`, this.container);
        const dateTextfieldInput = getElementBySelector(`[${DATA_TEXTIELD_INPUT}]`, dateTextfieldElement) as HTMLInputElement;
        dateTextfieldInput.value = date;
        this.dateInput = new DateInput(dateTextfieldInput);
        const timeTextfieldElement = getElementBySelector(`[${DATA_TEXTFIELD}="time"]`, this.container);
        this.timeInput = getElementBySelector(`[${DATA_TEXTIELD_INPUT}]`, timeTextfieldElement) as HTMLInputElement;
        this.timeInput.value = time;
    }

    private searchByQueryParams() {
        const urlParams = new URLSearchParams(window.location.search);
        const originId = urlParams.get(QUERY_PARAM_ORIGIN_ID);
        const originName = urlParams.get(QUERY_PARAM_ORIGIN_NAME);
        const destinationId = urlParams.get(QUERY_PARAM_DESTINATION_ID);
        const destinationName = urlParams.get(QUERY_PARAM_DESTINATION_NAME);
        const date = urlParams.get(QUERY_PARAM_DATE);
        const time = urlParams.get(QUERY_PARAM_TIME);
        const depArr = urlParams.get(QUERY_PARAM_DEP_ARR);

        if (originId && originName) {
            this.stopInputs.origin = {
                id: originId,
                name: originName,
            };
        }

        if (destinationId && destinationName) {
            this.stopInputs.destination = {
                id: destinationId,
                name: destinationName,
            };
        }

        if (date) {
            this.dateInput.value = date;
        }

        if (time) {
            this.timeInput.value = time;
        }

        if (depArr) {
            setRadioValue(RADIO_DEP_ARR, depArr, this.container);
        }

        if (this.stopInputs.origin && this.stopInputs.destination) {
            this.search();
        }
    }

    private setExpandEventListener() {
        const routes = document.querySelectorAll(`[${DATA_ROUTE}]`);
        this.accordionItems.forEach(item => item.removeEventListener());
        this.accordionItems = [];
        routes.forEach(route => {
            const details = getElementBySelector(`[${DATA_ROUTE_DETAILS}]`, route) as HTMLElement;
            const detailsWrapper = getElementBySelector(`[${DATA_ROUTE_DETAILS_WRAPPER}]`, route) as HTMLElement;
            const trigger = getElementBySelector(`[${DATA_ROUTE_EXPAND}]`, route);

            const accordionItem = new AccordionItem(detailsWrapper, details, trigger);
            accordionItem.addEventListener();
            this.accordionItems.push(accordionItem);
        });
    }

    private validate(): boolean {
        const validStops = this.stopInputs.validate();
        const validDate = this.dateInput.validate();
        const validAdvancedSearch = !this.advancedSearch || this.advancedSearch.validate();
        return validStops && validDate && validAdvancedSearch;
    }

    private redirectToSearchPage() {
        if (!this.validate()) {
            return;
        }

        window.location.replace(`${this.container.dataset.timetableSearchUri}?${this.queryParams}`);
    }

    private search() {
        if (!this.validate()) {
            return;
        }

        const url = new URL(window.location.origin + window.location.pathname);
        this.queryParams.forEach((value, key) => url.searchParams.set(key, value));
        window.history.pushState({}, "", url.toString());

        render(loader, this.resultContainer);

        this.advancedSearch.close();
        setElementClass(this.moreRoutes, CLASS_IS_VISIBLE, false);

        Api.getInstance().getRoutes(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.stopInputs.origin!.id,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.stopInputs.destination!.id,
      this.dateInput.value,
      this.timeInput.value,
      this.dateTimeDepArr,
      this.advancedSearch,
        ).then(result => {
            setElementClass(this.moreRoutes, CLASS_IS_VISIBLE, result?.routes.length > 0);
            this.renderResults(result);
        }).catch(() => {
            render(renderRoutesError(), this.resultContainer);
        });
    }

    private searchMore(button: Element, type: "before" | "after") {
        this.accordionItems.forEach(item => item.hide());
        button.classList.add(CLASS_IS_LOADING);
        Api.getInstance().getMoreRoutes(type)
            .then(this.renderResults.bind(this))
            .catch(() => {
                LayoutUtil.getInstance().enableSnackbar(ERROR_MESSAGE);
            })
            .finally(() => button.classList.remove(CLASS_IS_LOADING));
    }

    private renderResults(result: {routes: Route[], request: RequestInfo}) {
        const dateTime = new Date(result.request.date).toLocaleString(undefined, { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" });
        render(renderRoutes(result.routes, dateTime, `${result.request.sessionId}-${result.request.requestId}`), this.resultContainer);
        Modals.getInstance().refresh();
        this.setExpandEventListener();
    }
}
