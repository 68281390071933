import { TrafficInformationController, DATA_TRAFFIC_INFORMATION } from "./traffic-information";

const { Tabs } = await import("@/controller/tabs");
const { getElementBySelector } = await import("@/util/elements");

export const TAB_GROUP_NAME = "route";
export const TAB_NAME = "traffic-information-unplanned";
const UID = "unplanned";

export class TrafficInformationUnplannedController extends TrafficInformationController {
    private static instance: TrafficInformationUnplannedController;

    public static getInstance(): TrafficInformationUnplannedController {
        if (!TrafficInformationUnplannedController.instance) {
            TrafficInformationUnplannedController.instance = new TrafficInformationUnplannedController();
        }

        return TrafficInformationUnplannedController.instance;
    }

    constructor() {
        const container = getElementBySelector(`[${DATA_TRAFFIC_INFORMATION}="${UID}"]`) as HTMLElement;
        super(container);
        const tabs = Tabs.getInstance().tabsMap;
        const tabsEntry = tabs[TAB_GROUP_NAME];
        const tab = tabsEntry.tabs.find(t => t.uid === TAB_NAME);

        if (!tab) {
            throw new Error(`Tab with name ${TAB_NAME} not found`);
        }

        tab.addEventListener("show", () => {
            this.loadTrafficInformation(true).then(this.showTrafficInformation.bind(this));
        });
    }
}
