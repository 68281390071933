import { plainToInstance } from "class-transformer";
import Route from "@/model/vrr/route";
import TrafficInformation from "@/model/vrr/traffic-information";
import AdvancedSearch from "@/model/vrr/advanced-search";

export enum MeansOfTransportType {
  SBahn = "sbahn",
  UBahn = "ubahn",
  Strassenbahn = "strassenbahn",
  Bus = "bus",
  Regionalzug = "regionalzug",
  IC = "ic",
  ICE = "ice",
}

export const MEANS_OF_TRANSPORT_MAPPING: Record<MeansOfTransportType, { ids: number[], icon: string }> = {
    [MeansOfTransportType.SBahn]: { ids: [1], icon: "icon-tram-fast" },
    [MeansOfTransportType.UBahn]: { ids: [2], icon: "icon-metro" },
    [MeansOfTransportType.Strassenbahn]: { ids: [4], icon: "icon-tram" },
    [MeansOfTransportType.Bus]: { ids: [5, 6, 7, 10, 19], icon: "icon-bus" },
    [MeansOfTransportType.Regionalzug]: { ids: [13], icon: "icon-train" },
    [MeansOfTransportType.IC]: { ids: [15], icon: "icon-ICEC" },
    [MeansOfTransportType.ICE]: { ids: [16], icon: "icon-ICE" },
};

export function getIcon(id: number): string | undefined {
    return Object.values(MEANS_OF_TRANSPORT_MAPPING).find(mot => mot.ids.includes(id))?.icon;
}

export function getEnum(id: number): MeansOfTransportType {
    const key = Object.keys(MeansOfTransportType).find(key => {
        const enumValue = MeansOfTransportType[key as keyof typeof MeansOfTransportType];
        const mappingEntry = MEANS_OF_TRANSPORT_MAPPING[enumValue];
        return mappingEntry && mappingEntry.ids.includes(id);
    });
    return MeansOfTransportType[key as keyof typeof MeansOfTransportType];
}

export interface Stop {
    id: string
    name: string
}

export interface MeansOfTransport {
  id: number
  name?: string
  destination?: string
  footpath?: "from" | "to"
}

const PATH = "/api/";

export interface RequestInfo {
  sessionId: string
  requestId: string
  date: string
}

export class Api {
    private static instance: Api;

    private sessionId: string;
    private requestId: string;

    public static getInstance(): Api {
        if (!Api.instance) {
            Api.instance = new Api();
        }

        return Api.instance;
    }

    public getTrafficInformation(unplanned?: boolean): Promise<TrafficInformation[]> {
        const url = new URL(window.location.origin + PATH + (unplanned ? "unplanned" : "diversion"));
        return this.customFetch(url.toString()).then(response => response.json().then(this.parseTrafficInformation));
    }

    private parseTrafficInformation(diversions: TrafficInformation[]): TrafficInformation[] {
        return diversions.map(diversion => plainToInstance(TrafficInformation, diversion));
    }

    public getStops(name: string): Promise<Stop[]> {
        const url = new URL(window.location.origin + PATH + "stop");
        url.searchParams.set("name", name);
        return this.customFetch(url.toString()).then(response => response.json());
    }

    public getRoutes(
        origin: string, destination: string,
        date: string, time: string, dateTimeDepArr: string,
        advanced?: AdvancedSearch,
    ): Promise<{routes: Route[], request: RequestInfo}> {
        const url = new URL(window.location.origin + PATH + "route");
        url.searchParams.set("origin", origin);
        url.searchParams.set("destination", destination);
        url.searchParams.set("date", date);
        url.searchParams.set("time", time);
        url.searchParams.set("dateTimeDepArr", dateTimeDepArr);

        function setOptionalStringParam(name: string, value?: string) {
            if (value) {
                url.searchParams.set(name, value);
            }
        }

        function setOptionalBooleanParam(name: string, value: boolean) {
            if (value) {
                url.searchParams.set(name, "1");
            }
        }

        if (advanced) {
            setOptionalStringParam("stopover", advanced.stopover);
            setOptionalStringParam("walkingSpeed", advanced.walkingSpeed);
            setOptionalStringParam("routeType", advanced.routeType);
            setOptionalStringParam("maxChanges", advanced.maxChanges);
            setOptionalBooleanParam("carriageOfBicycles", advanced.carriageOfBicycles);
            setOptionalBooleanParam("mobilityNoEscalators", advanced.mobilityNoEscalators);
            setOptionalBooleanParam("mobilityNoElevators", advanced.mobilityNoElevators);
            setOptionalBooleanParam("mobilityNoSolidStairs", advanced.mobilityNoSolidStairs);
            setOptionalBooleanParam("mobilityLowPlatformVehicle", advanced.mobilityLowPlatformVehicle);
            setOptionalBooleanParam("mobilityWheelchair", advanced.mobilityWheelchair);

            if (advanced.excludedMeansOfTransport) {
                advanced.excludedMeansOfTransport.forEach(excluded => {
                    MEANS_OF_TRANSPORT_MAPPING[excluded].ids.forEach(id => {
                        url.searchParams.append("excludedMeans", id.toString());
                    });
                });
            }
        }

        return this.customFetch(url.toString()).then(response => response.json().then(this.parseRoutes.bind(this)));
    }

    public getMoreRoutes(type: "before" | "after"): Promise<{routes: Route[], request: RequestInfo}> {
        const url = new URL(window.location.origin + PATH + (type === "before" ? "routeBefore" : "routeAfter"));
        url.searchParams.set("sessionId", this.sessionId);
        url.searchParams.set("requestId", this.requestId);

        return this.customFetch(url.toString()).then(response => response.json().then(this.parseRoutes.bind(this)));
    }

    private parseRoutes(json: {routes: Route[], request: RequestInfo}) {
        if (!json) {
            throw new Error("JSON is undefined");
        }

        const result = {
            request: json.request,
            routes: json.routes.map((route: unknown) => plainToInstance(Route, route)),
        };

        this.sessionId = json.request.sessionId;
        this.requestId = json.request.requestId;

        return result;
    }

    private customFetch(url: string): Promise<Response> {
        return fetch(url, { cache: "no-store" });
    }
}
