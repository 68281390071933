const { setElementClass } = await import("@/util/elements");
const { CLASS_HAS_ERROR } = await import("@/util/layout");

export default class DateInput {
    private element: HTMLInputElement;
    private validationInitialized = false;

    get value(): string {
        return this.element.value;
    }

    set value(value: string) {
        this.element.value = value;
    }

    constructor(element: HTMLInputElement) {
        this.element = element;

        this.element.addEventListener("blur", () => this.validate());

        this.element.addEventListener("input", () => {
            if (this.validationInitialized) {
                this.validate();
            }
        });
    }

    public validate(force = false): boolean {
        if (force) {
            this.validationInitialized = true;
        }

        // Date is valid if value is empty or value is a valid date and after the current date
        const isValid = this.element.validity.valueMissing || new Date(new Date().toDateString()) <= new Date(this.element.value);

        setElementClass(this.element, CLASS_HAS_ERROR, !isValid);
        this.validationInitialized = true;

        return isValid;
    }
}
