import { MeansOfTransportType } from "@/controller/vrr/api";
import StopInput from "@/model/vrr/stop-input";
import AccordionItemType from "@/model/accordion-item";

const { getElementBySelector, getRadioValue } = await import("@/util/elements");
const { default: AccordionItem } = await import("@/model/accordion-item");

const DATA_ADVANCED_SEARCH = "data-advanced-search";
const DATA_ADVANCED_SEARCH_BUTTON = DATA_ADVANCED_SEARCH + "-button";
const DATA_ADVANCED_SEARCH_CONTENT = DATA_ADVANCED_SEARCH + "-content";
const DATA_ADVANCED_SEARCH_CONTENT_WRAPPER = DATA_ADVANCED_SEARCH_CONTENT + "-wrapper";

export default class AdvancedSearch {
    private container: Element;
    private stopoverInput: StopInput;
    private accordionItem: AccordionItemType;
    private meansOfTransportInputs: Partial<Record<MeansOfTransportType, HTMLInputElement>> = {};
    private carriageOfBicyclesInput: HTMLInputElement;
    private mobilityNoEscalatorsInput: HTMLInputElement;
    private mobilityNoElevatorsInput: HTMLInputElement;
    private mobilityNoSolidStairsInput: HTMLInputElement;
    private mobilityLowPlatformVehicleInput: HTMLInputElement;
    private mobilityWheelchairInput: HTMLInputElement;

    public get stopover(): string | undefined {
        return this.stopoverInput.selectedStop?.id;
    }

    public get excludedMeansOfTransport(): MeansOfTransportType[] {
        return Object.values(MeansOfTransportType).filter(type => !this.meansOfTransportInputs[type]?.checked);
    }

    public get walkingSpeed(): string {
        return getRadioValue("walking-speed", this.container);
    }

    public get routeType(): string {
        return getRadioValue("route-type", this.container);
    }

    public get maxChanges(): string {
        return getRadioValue("max-changes", this.container);
    }

    public get carriageOfBicycles(): boolean {
        return this.carriageOfBicyclesInput.checked;
    }

    public get mobilityNoEscalators(): boolean {
        return this.mobilityNoEscalatorsInput.checked;
    }

    public get mobilityNoElevators(): boolean {
        return this.mobilityNoElevatorsInput.checked;
    }

    public get mobilityNoSolidStairs(): boolean {
        return this.mobilityNoSolidStairsInput.checked;
    }

    public get mobilityLowPlatformVehicle(): boolean {
        return this.mobilityLowPlatformVehicleInput.checked;
    }

    public get mobilityWheelchair(): boolean {
        return this.mobilityWheelchairInput.checked;
    }

    constructor(container: Element) {
        this.container = getElementBySelector(`[${DATA_ADVANCED_SEARCH}]`, container);
        const advancedSearchButton = getElementBySelector(`[${DATA_ADVANCED_SEARCH_BUTTON}]`, this.container);
        const contentWrapper = getElementBySelector(`[${DATA_ADVANCED_SEARCH_CONTENT_WRAPPER}]`, this.container) as HTMLElement;
        const content = getElementBySelector(`[${DATA_ADVANCED_SEARCH_CONTENT}]`, this.container) as HTMLElement;

        this.accordionItem = new AccordionItem(contentWrapper, content, advancedSearchButton);
        this.accordionItem.addEventListener();

        this.stopoverInput = new StopInput(container, "stop-via");

        this.carriageOfBicyclesInput = document.getElementById("carriage-of-bicycles") as HTMLInputElement;
        this.mobilityNoEscalatorsInput = document.getElementById("mobility-no-escalators") as HTMLInputElement;
        this.mobilityNoElevatorsInput = document.getElementById("mobility-no-elevators") as HTMLInputElement;
        this.mobilityNoSolidStairsInput = document.getElementById("mobility-no-solid-stairs") as HTMLInputElement;
        this.mobilityLowPlatformVehicleInput = document.getElementById("mobility-low-platform-vehicle") as HTMLInputElement;
        this.mobilityWheelchairInput = document.getElementById("mobility-wheelchair") as HTMLInputElement;

        Object.values(MeansOfTransportType).forEach(type => {
            this.meansOfTransportInputs[type] = document.getElementById(`means-of-transport-${type}`) as HTMLInputElement;
        });
    }

    public validate(): boolean {
        return this.stopoverInput.validate();
    }

    public close(): void {
        this.accordionItem.hide();
    }
}
