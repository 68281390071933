import { html, TemplateResult } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import PartialRoute from "@/model/vrr/partial-route";

export default function info(partialRoute: PartialRoute, id: string): TemplateResult | string {
    return (partialRoute.info.length + partialRoute.warning.length) > 0
        ? html`
            <div class="route-info-wrapper" data-modal-content-wrapper="partial-route-${id}">
                <div class="route-info" data-modal-content="partial-route-${id}">
                    <span class="route-info__close icon-close" data-modal-close="partial-route-${id}"></span>
                    <div class="route-info__headline">Weitere Informationen zu ${partialRoute.meansOfTransport.name}</div>
                    ${partialRoute.warning.map(warning => html`
                        <div class="route-info__entry">
                            <div class="route-info__icon"><span class="icon-alert"></span></div>
                            <div class="route-info__content">${unsafeHTML(warning)}</div>
                        </div>
                    `)}
                    ${partialRoute.info.map(info => html`
                        <div class="route-info__entry">
                            <div class="route-info__icon"><span class="icon-info"></span></div>
                            <div class="route-info__content">${info}</div>
                        </div>
                    `)}
                </div>
            </div>
            `
        : "";
}
