import "reflect-metadata";
import { CookieConsent } from "./controller/cookie-consent/cookie-consent";
import { DesktopNavigation } from "./controller/navigation/desktop";
import { MobileNavigation } from "./controller/navigation/mobile";
import { SearchAutocomplete } from "./controller/search-autocomplete";
import { Tabs } from "./controller/tabs";
import { TrafficInformationPlannedController } from "./controller/vrr/traffic-information-planned";
import { TrafficInformationUnplannedController } from "./controller/vrr/traffic-information-unplanned";
import { TrafficInformationUnplannedManualController } from "./controller/vrr/traffic-information-unplanned-manual";
import { Timetable } from "./controller/vrr/timetable";
import { Tooltips } from "./controller/tooltips";

document.readyState !== "loading" ? init() : document.addEventListener("DOMContentLoaded", init);

function init(): void {
    CookieConsent.getInstance();
    DesktopNavigation.getInstance();
    MobileNavigation.getInstance();
    SearchAutocomplete.getInstance();
    Tabs.getInstance();
    TrafficInformationPlannedController.getInstance();
    TrafficInformationUnplannedController.getInstance();
    TrafficInformationUnplannedManualController.getInstance();
    Timetable.getInstance();
    Tooltips.getInstance();
}
