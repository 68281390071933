import { Dropdown } from "@/model/dropdown";
import { LayoutUtil as LayoutUtilType } from "@/util/layout";

const { Dropdowns } = await import("@/controller/dropdowns");
const { LayoutUtil, handleMediaQuery, getBreakpoint } = await import("@/util/layout");
const { ScrollIndicator } = await import("@/util/scroll-indicator");

const DROPDOWN_PREFIX = "nav";

export class DesktopNavigation {
    private static instance: DesktopNavigation;

    private layoutUtil: LayoutUtilType;

    private dropdowns: { [key: string]: Dropdown };

    public static getInstance(): DesktopNavigation {
        if (!DesktopNavigation.instance) {
            DesktopNavigation.instance = new DesktopNavigation();
        }

        return DesktopNavigation.instance;
    }

    private constructor() {
        this.dropdowns = Dropdowns.getInstance().dropdownMap;
        this.layoutUtil = LayoutUtil.getInstance();

        this.closeDropdownOnViewChange();
        this.updateHeaderHeightOnScroll();
    }

    // Hide open dropdown changing to mobile view
    private closeDropdownOnViewChange(): void {
        const breakpoint = getBreakpoint("mobile-max");
        handleMediaQuery(`(max-width: ${breakpoint})`, (mediaQuery) => {
            if (mediaQuery.matches) {
                const key = Object.keys(this.dropdowns).find(key => key.startsWith(DROPDOWN_PREFIX) && this.dropdowns[key].isActive);

                if (key) {
                    this.dropdowns[key].hide();
                }
            }
        });
    }

    private updateHeaderHeightOnScroll(): void {
        const scrollIndicator = new ScrollIndicator({
            content: document.body,
            direction: "top",
        });
        scrollIndicator.watch((detached) => {
        // Don't expand/collapse header when scrolling is disabled
            if (this.layoutUtil.scrollingDisabled) {
                return;
            }
            detached ? this.layoutUtil.collapseHeader() : this.layoutUtil.expandHeader();
        });
    }
}
